"use client";

import * as React from "react";
import { RxMoon, RxSun } from "react-icons/rx";
import { useTheme } from "next-themes";

export function ModeToggle() {
  const { setTheme, theme, resolvedTheme } = useTheme();
  const toggleFunc = () => {
    if (resolvedTheme === "dark") {
      setTheme("light");
    } else if (resolvedTheme === "light") {
      setTheme("dark");
    } else setTheme("light");
  };

  return (
    <button
      type="button"
      onClick={toggleFunc}
      className=" text-foreground flex"
    >
      {theme === "dark" ? (
        <RxSun
          className={`${
            theme === "dark" ? "rotate-0 scale-100" : "rotate-90 "
          } h-[1.2rem] w-[1.2rem]  duration-500`}
        />
      ) : (
        <RxMoon
          className={`${
            theme === "light" && "rotate-0 scale-100"
          }  h-[1.2rem] w-[1.2rem] rotate-90  duration-500`}
        />
      )}
    </button>
    // <DropdownMenu className="pointer-events-none">
    //   <DropdownMenuTrigger asChild>
    //     <Button
    //       onClick={toggleFunc}
    //       variant="ghost"
    //       size="icon"
    //       className="pointer-events-none text-slate-400 hidden md:flex"
    //     >
    //       <RxSun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
    //       <RxMoon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
    //       <span className="sr-only">Toggle theme</span>
    //     </Button>
    //   </DropdownMenuTrigger>
    //   <DropdownMenuContent align="end">
    //     <DropdownMenuItem onClick={() => setTheme("light")}>
    //       Light
    //     </DropdownMenuItem>
    //     <DropdownMenuItem onClick={() => setTheme("dark")}>
    //       Dark
    //     </DropdownMenuItem>
    //     <DropdownMenuItem onClick={() => setTheme("system")}>
    //       System
    //     </DropdownMenuItem>
    //   </DropdownMenuContent>
    // </DropdownMenu>
  );
}
