"use client";

import Image from "next/image";
import logo from "@/public/logo/centiiv-logo.svg";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import { ModeToggle } from "./darkModeToggler";
import { AvatarDropdown } from "./avatarDropdown";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {
  getCredentials,
  getNewAccessToken,
  getStoredAccessToken,
  getStoredGA_AccessToken,
  stopScroll,
} from "@/lib/utils";
import Loader from "./ui/Loader";

import { useTheme } from "next-themes";
import NotificationModal from "./NotificationModal";
import ModeToggleLiveOrTest from "./ModeToggleLiveOrTest";

const Header = () => {
  const { setTheme } = useTheme();
  const pathname = usePathname();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      if (pathname === "/") {
        setTheme("light");
      }
      const user = getCredentials();
      const accessToken = getStoredAccessToken();
      const gaAccessToken = getStoredGA_AccessToken();

      if ((user && accessToken) || gaAccessToken) {
        setUserInfo(user);
        setLoading(false);
      } else {
        setUserInfo(null);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const showNotificationModalFunc = () => {
    setShowNotificationModal(true);
    stopScroll(true);
  };
  const hideNotificationModalFunc = () => {
    setShowNotificationModal(false);
    stopScroll(false);
  };
  const isPublicScreen = () => {
    if (
      pathname === "/" ||
      pathname === "/about-us" ||
      pathname === "/pricing" ||
      pathname.startsWith("/auth")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <section className="relative">
      <header
        className={`${
          isPublicScreen()
            ? "bg-white text-black border-b-white"
            : "bg-background"
        } font-codecpro py-4 fixed   border-b-2 h-[80px]   top-0 left-0 w-full  z-[100]`}
      >
        <section className="w-[95%]      flex items-center justify-between max-w-screen-2xl mx-auto ">
          <Link className="" href="/">
            <Image
              src={logo}
              alt="Centiiv"
              width={100}
              height={20}
              className="w-20 sm2:w-28 aspect-auto "
              priority
            />
          </Link>

          <section className="flex items-center justify-center   gap-4">
            {isPublicScreen() ? null : <ModeToggle />}

            {userInfo && !isPublicScreen() && (
              <>
                {/* <ModeToggleLiveOrTest /> */}
                <button
                  onClick={() => showNotificationModalFunc()}
                  className="flex p-2 otp"
                  variant="ghost"
                  size="icon"
                >
                  <IoIosNotificationsOutline
                    size={30}
                    className="rotate-0 scale-100 transition-all"
                  />
                </button>

                {showNotificationModal && (
                  <NotificationModal
                    notifications={notifications}
                    hideNotificationModalFunc={hideNotificationModalFunc}
                  />
                )}
              </>
            )}
            {loading ? (
              <div className="">
                <Loader />
              </div>
            ) : (
              <>
                <div className="hidden md3:flex items-center text-sm gap-6 ">
                  {pathname === "/" ||
                  pathname === "/about-us" ||
                  pathname === "/pricing" ? (
                    <>
                      <Link href="/">Home</Link>
                      <Link href="/pricing">Pricing</Link>
                      <Link href="/about-us">About Us</Link>
                      <Link href="https://centiiv.gitbook.io/centiiv/">
                        Docs
                      </Link>
                    </>
                  ) : null}

                  {!userInfo && !pathname.startsWith("/auth") && (
                    <>
                      <Link
                        className="px-6 py-2 rounded-md text-white  bg-primary_blue"
                        href="/auth/register"
                      >
                        Register
                      </Link>
                      <Link href="/auth/login">Login</Link>
                    </>
                  )}
                </div>

                <AvatarDropdown userProfile={userInfo} />
              </>
            )}
          </section>
        </section>
      </header>
    </section>
  );
};

export default Header;
