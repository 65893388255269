import { axiosInstance } from "@/lib/constants";
import { getCredentials, handlePostError } from "@/lib/utils";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";

const ModeToggleLiveOrTest = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  useEffect(
    () => {
      const user = getCredentials();
      if (user) {
        setIsVerified(user.tier > 1 ? true : false);
      }
    },
    // eslint-disable-next-line
    []
  );

  const toggleToken = (mode) => {
    const modeUrl = mode === "live" ? "switch_to_live" : "switch_to_sandbox";
    axiosInstance
      .post(`/auth/${modeUrl}`)
      .then((res) => {
        if (mode === "live") {
          toast.success("LIVE MDOE ACTIVATED");
        } else {
          toast.success("SANDBOX MODE ACTIVATED");
        }
        localStorage.setItem("accessToken", res.data.data.accessToken);
        localStorage.setItem("userInfo", JSON.stringify(res.data.data.user));
        console.log(res.data);
      })
      .catch((error) => {
        // console.log(error);
        // toast.error("Error switching mode");

        if (mode === "test") {
          isToggled(false);
        }

        handlePostError(error);
      });
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);

    if (isToggled) {
      toggleToken("test");
    } else {
      toggleToken("live");
    }
  };

  return (
    <div className="flex items-center gap-2 w-20 h-8  ">
      <button
        disabled={!isVerified}
        onClick={handleToggle}
        className={`relative  block items-center h-6 rounded-full w-11  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
          isToggled ? "bg-primary_blue" : "bg-gray-200"
        }`}
      >
        <span className="sr-only">Toggle button</span>
        <div
          className={`inline-block w-4 h-4 transform absolute left-0 top-1 transition ease-in-out duration-200 rounded-full bg-white ${
            isToggled ? "translate-x-6" : "translate-x-1"
          }`}
        />
      </button>
      <span className="text-xs font-semibold uppercase">
        {isToggled ? "Live" : "Test"}
      </span>
    </div>
  );
};

export default ModeToggleLiveOrTest;
